import { Box, Button, Card, Loader, MarketingPageLayout, MarketingPageLayoutContent, Page, WixDesignSystemProvider, Image } from "@wix/design-system";
import "@wix/design-system/styles.global.css";
import HeroImage from './hero.png';
import { ReactTagManager } from 'react-gtm-ts';

const tagManagerArgs = {
  code: 'GTM-WZQPMC7'
}

function App() {
  ReactTagManager.init(tagManagerArgs)
  const token = new URLSearchParams(window.location.search).get('token');

  if (token) {
    window.location.href = `https://www.wix.com/installer/install?token=${token}&appId=2c6a845a-43f4-4f9d-b7f1-4bb3ae6ecbe0&redirectUrl=https://certifiedcode.wixsite.com/threads-embed/_functions/@certifiedcode/base-backend/auth`;
    return <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Page height='100vh'>
        <Page.Content>
          <Box height={"90vh"} direction="vertical" verticalAlign="middle">
            <Loader text="Loading" />
          </Box>
        </Page.Content>
      </Page></WixDesignSystemProvider>
  }

  return <WixDesignSystemProvider features={{ newColorsBranding: true }}>
    <Card>
      <MarketingPageLayout
        removeImageHorizontalPadding
        removeImageVerticalPadding
        content={
          <Box height="840px" verticalAlign="middle">
            <MarketingPageLayoutContent
              title="Place Threads thread everywhere on your site"
              content="Threads Embed is an app that allows you to embed Threads thread on your Wix site. It's easy to use and free."
              actions={<Box direction="horizontal" gap="SP1">
                {/* <Button onClick={() => { window.Intercom("showArticle", 8100023) }}>See how</Button> */}
                <Button skin='inverted' as='a' href='https://www.wix.com/app-market/add-review/2c6a845a-43f4-4f9d-b7f1-4bb3ae6ecbe0' target='_blank'>Rate Threads Embed 5 stars</Button></Box>}
            />
          </Box>
        }
        image={<Image borderRadius={0} src={HeroImage} />}
      />
    </Card>
  </WixDesignSystemProvider >
}

export default App;
